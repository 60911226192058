
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import {CONTAINER_STORE_NAME} from "@/store/container.store";
import Employee from "@/models/Employee";
import {namespace} from "vuex-class";
import {COMPANY_STORE_NAME, CompanyStoreActions} from "@/store/company.store";
import EmployeeRepository from "@/api/repositories/EmployeeRepository";

const ContainerStore = namespace(CONTAINER_STORE_NAME);
const CompanyStore = namespace(COMPANY_STORE_NAME);

@Component({
  components: {
    EmployeeListComponent: () => import(
      /* webpackChunkName: "EmployeeListComponent" */
      '@/components/Employees/EmployeeList.component.vue'
    ),
    EditEmployeeComponent: () => import(
        /* webpackChunkName: "EditEmployeeComponent" */
        '@/components/Employees/EditEmployee.component.vue'
        ),
    DeleteEmployeeComponent: () => import(
      /* webpackChunkName: "DeleteEmployeeComponent" */
      '@/components/Employees/ConfirmDeleteEmployee.component.vue'
    ),
  }
})
export default class EmployeeOverviewView extends Vue {
  @CompanyStore.Action(CompanyStoreActions.GET_EMPLOYEES)
  private loadEmployees!: (companyId: string) => Promise<Employee[]>;

  /**
   * list of employees of the company
   * @private
   */
  private employees: Employee[] = [];

  private showEditDialog: boolean = false;
  private showNewDialog: boolean = false;
  private showDeleteDialog: boolean = false;

  private search: string = '';

  private isLoading = false;

  /**
   * recently clicked employee
   * @private
   */
  private selectedEmployee?: Employee;

  /**
   * gets all employees of the current company
   */
  async created() {
    try {
      this.isLoading = true;
      this.employees = await this.loadEmployees(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when user clicks on list entry, opens the edit modal with the user that was clicked
   * @param item
   * @private
   */
  private onListItemClicked(item: Employee): void {
    this.selectedEmployee = item;
    this.showEditDialog = true;
  }

  private onListItemDeleted(item: Employee): void {
    this.selectedEmployee = item;
    this.showDeleteDialog = true;
  }

  private async onDeleteEmployeeConfirmed() {
    try {
      await EmployeeRepository.deleteEmployee(this.selectedEmployee!.id);
      await this.closeDialog(true);
    } catch(e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * is called when the user wants to close the modal, closes the modal and checks if data has changed, if so,
   * reloads the list of employees again to show the updated list of users
   * @param reload
   * @private
   */
  private async closeDialog(reload: boolean) {
    this.showEditDialog = false;
    this.showNewDialog = false;
    this.showDeleteDialog = false;
    if (reload) {
      try {
        this.employees = await this.loadEmployees(this.$route.params.id);
      } catch (e) {
        this.$handleError(e);
      }
    }
  }
}
