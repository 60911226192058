import {AxiosResponse} from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import Employee from "@/models/Employee";

/**
 * repository that deals with all the api requests that have to do with employees
 */
export default class EmployeeRepository {
    private static baseUrl: string = 'api/employee';

    /**
     * gets the user based on the token that is used
     */
    public static getEmployeeByToken(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/auth`);
    }

    public static createEmployee(companyId: string, employee: Employee): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}/register`, {
            'email': employee.account.email,
            'companyId': companyId,
            'firstName': employee.firstName,
            'lastName': employee.lastName
        });
    }

    public static editEmployee(employeeId: string, employee: Employee): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${employeeId}`, {
            'email': employee.account.email,
            'firstName': employee.firstName,
            'lastName': employee.lastName,
        });
    }

    public static deleteEmployee(employeeId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}/${employeeId}`);
    }
}
